import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "catering"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`Catering`}</h3>
    <p>{`[img][Sommerfest.jpg]`}</p>
    <p>{`Du hast Bock auf ein Catering von limeriki tofu?
Mit guten Sojaprodukten und frischem Gemüse und lecker gewürzt?`}</p>
    <p>{`[Dann sprich uns an][mailto:catering@limeriki-tofu.de]`}</p>
    <p>{`Dogmatismus ist nicht so unser Ding. (Aber natürlich sind unsere Gerichte vor alllem vegan und vegetarisch orientiert)`}</p>
    <p>{`[Rezept 1]`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      